<template>
    <div class="px-3 py-2"> 
        <v-form
            ref="forinv"
            lazy-validation
        >
        <b-sidebar id="add_qinvoice" aria-labelledby="sidebar-no-header-title" no-header backdrop width="90rem" style="direction:ltr" right title="اضافة فاتورة" shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>اضافة فاتورة</span>
        </div>
        <div @click="hide" id="HideMe" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>اغلاق</span>
      </div>
    </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <v-row v-if="$store.state.licenseType.cars || $store.state.licenseType.bill">
                <v-col cols="12" md="2" sm="12">
                    <v-text-field
                        label="رقم الفاتورة"
                        v-model="invoice.billid" 
                        :rules="fieldRules"
                        required
                        readonly
                    >{{ invoice.billid }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <v-text-field
                        label="رقم الاتصال"
                        v-model="customer.mobile"
                        size="10"
                        id="CusMobile"
                        @change="getName()"
                        >{{ customer.mobile }}</v-text-field>
                </v-col>
                <v-col cols="12" md="5" sm="8" xs="12">
                    <v-text-field
                        label="اسم العميل الكامل"
                        v-model="customer.full_name"
                        id="CusMobile"
                        >{{ customer.full_name }}</v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="customer.cctype"
                        :items="customer.ctype"
                        label="نوع العميل"
                        @change="changeCtype()"
                        ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="$store.state.licenseType.parts">
                <v-col cols="12" md="2" sm="12">
                    <v-text-field
                        label="رقم الفاتورة"
                        v-model="invoice.billid" 
                        :rules="fieldRules"
                        required
                        readonly
                    >{{ invoice.billid }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <v-text-field
                        label="رقم الجوال"
                        v-model="customer.mobile"
                        :rules="fieldRules"
                        size="10"
                        id="CusMobile"
                        @change="getName()"
                        >{{ customer.mobile }}</v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="8" xs="12">
                    <v-text-field
                        label="اسم العميل الكامل"
                        v-model="customer.full_name"
                        :rules="fieldRules"
                        id="CusMobile"
                        >{{ customer.full_name }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <v-text-field
                        label="نوع السيارة"
                        v-model="car.model"
                        :rules="fieldRules"
                        required
                        id="CusMobile"
                        @change="getName()"
                        >{{ car.model }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="customer.cctype"
                        :items="customer.ctype"
                        label="نوع العميل"
                        @change="changeCtype()"
                        ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="showcamp">
                <v-col cols="12" md="4" sm="12" xs="12">
                    <v-text-field
                        label="اسم الشركة"
                        v-model="company.fullname"
                        id="CusMobile"
                        @change="getName()"
                        >{{ company.fullname }}</v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" xs="12">
                    <v-text-field
                        label="عنوان الشركة"
                        v-model="company.address"
                        id="CusMobile"
                        @change="getName()"
                        >{{ company.address }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <v-text-field
                        label="الرقم الضريبي"
                        v-model="company.vatid"
                        id="CusMobile"
                        @change="getName()"
                        >{{ company.fullname }}</v-text-field>
                </v-col>
            </v-row>
            <v-row  v-if="$store.state.licenseType.cars && showcars">
                <v-col cols="12" md="2" sm="12">
                    <v-text-field
                        label="لوحة السيارة"
                        v-model="car.plate_number" 
                        :rules="fieldRules"
                        required
                    >{{ car.plate_number }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <v-text-field
                        label="نوع السيارة"
                        v-model="car.model"
                        :rules="fieldRules"
                        required
                        id="CusMobile"
                        @change="getName()"
                        >{{ car.model }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12">
                    <v-text-field
                        label="سنة الصنع"
                        v-model="car.made_year"
                        required
                        :rules="fieldRules"
                        id="CusMobile"
                        >{{ car.made_year }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12">
                    <v-text-field
                        label="اللون"
                        v-model="car.color"
                        >{{ car.color }}</v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="invoice.invtype"
                        :items="invoice.invtypes"
                        label="نوع الفاتورة"
                        ></v-select>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="invoice.vtype"
                        :items="invoice.vattype"
                        label="نوع الضريبة"
                        @change="changePtype()"
                        ></v-select>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="invoice.paytype"
                        :items="invoice.paytypes"
                        label="طريقة الدفع"
                        @change="changePtype()"
                        ></v-select>
                </v-col>
                <v-col cols="12" md="3" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="invoice.userid"
                        :items="invoice.users"
                        label="البائع"
                        @change="changePtype()"
                        ></v-select>
                </v-col>
                <v-col cols="12" md="3" sm="6" xs="12">
                    <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="invoice.date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="invoice.date"
                            label="تاريخ الفاتورة"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        >{{ invoice.date }}</v-text-field>
                        </template>
                        <v-date-picker
                        v-model="invoice.date"
                        scrollable
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modal = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(invoice.date)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
            </v-row>
            <v-row>
                <v-window>تفاصيل الفاتورة</v-window>
                <v-simple-table striped hover style="width:100%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>رمز الصنف</th>
                                <th style="width:35%" class="text-center">الوصف</th>
                                <th class="text-center">الكمية</th>
                                <th class="text-center">السعر</th>
                                <th class="text-center">الاجمالي</th>
                                <th class="text-center">الضريبة</th>
                                <th class="text-center">المجموع</th>
                                <th class="text-center"></th>
                            </tr>
                        </thead>
                        <tbody id="tablerow">
                            <tr v-for="(item,index) in tbrows" :key="index">
                                <td>{{ item.itemcode }}</td>
                                <td>{{ item.item_name }}</td>
                                <td>{{ item.qty }}</td>
                                <td>{{ item.item_price }}</td>
                                <td>{{ item.total }}</td>
                                <td>{{ item.vat }}</td>
                                <td>{{ item.ftotal }}</td>
                                <td>
                                    <b-button type="button" variant="danger" style="width:65px" @click="removeItem(index)">حذف</b-button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <v-text-field
                                        v-model="newrow.itemcode"
                                        @change="getItemname"
                                        label="رمز الصنف">
                                    </v-text-field>
                                </td>
                                <td>
                                    <v-combobox
                                        v-model="newrow.item_name"
                                        :items="itemsworkdes"
                                        small-chips
                                        label="المنتجات"
                                    ></v-combobox>
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="newrow.qty"
                                        @change="calcKit()"
                                        type="number"
                                        id="newitemqty"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="newrow.item_price"
                                        @change="calcKit()"
                                        type="number"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="newrow.total"
                                        @change="calcKit()"
                                        type="number"
                                        readonly
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="newrow.vat"
                                        @change="calcKit()"
                                        type="number"
                                        readonly
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="newrow.ftotal"
                                        @change="calcKit()"
                                        type="number"
                                        readonly
                                    ></v-text-field>
                                </td>
                                <td>
                                    <b-button type="button" class="btn-sm" variant="success" @click="addNewRow()">اضافة</b-button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"> % الخصم</th>
                                <th class="text-center">
                                    <v-text-field
                                        v-model="invoice.discount"
                                        @change="calcAll()"
                                        type="number"
                                    ></v-text-field>
                                </th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                            </tr>
                            <tr>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center">المجموع</th>
                                <th class="text-center">{{ full_total }}</th>
                                <th class="text-center">{{ full_vat }}</th>
                                <th class="text-center">{{ full_ftotal }}</th>
                                <th class="text-center"></th>
                            </tr>
                        </tfoot>
                        
                    </template>
                </v-simple-table>
            </v-row>
            <v-row>
                <v-col cols="12" md="4" sm="6" xs="12">
                    <v-text-field
                        label="المبلغ النقدي"
                        v-if="invoice.paytype == 1 || invoice.paytype == 3"
                        v-model="invoice.payment.cash"
                        type="number"
                        >{{ invoice.payment.cash }}</v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="6" xs="12">
                    <v-text-field
                        v-if="invoice.paytype == 2 || invoice.paytype == 3"
                        label="بواسطة الشبكة"
                        v-model="invoice.payment.span"
                        type="number"
                        >{{ invoice.payment.span }}</v-text-field>
                </v-col>
            </v-row>
        </div>
        </template>
        <template #footer="" class="shadow">
        <div class="d-flex text-light align-items-center px-3 py-2">
          <b-button type="button" @click="addInvoice()" id="addBTN" variant="success" class="ma-2 btn-sm" style="width:100px;">إنشاء الفاتورة</b-button>
        </div>
      
      </template>
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
      </div>
</template>

<script>
import axios from 'axios'
export default{
    props:[
        'custom','prods','cardid','quots'
    ],
    data() {
        return {
            showcars: false,
            payby: 1,
            itemsworkdes:[
                
            ],
            car: {
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
            },
            modal: false,
            full_total: 0,
            full_vat: 0,
            full_ftotal: 0,
            showcamp: false,
            company:{
                fullname: this.quots.company_name,
                vatid: this.quots.vatid
            },
            customer: {
                id: this.quots.customerid,
                mobile: this.quots.mobile,
                full_name: this.quots.full_name,
                cctype: 2,
                ctype: [
                    {
                        text: 'عميل فردي',
                        value: 1,
                    },
                    {
                        text: 'عميل شركة',
                        value: 2,
                    }
                ]
            },
            invoice:{
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                payment:{
                    cash: 0,
                    span: 0
                },
                billid: 1,
                discount:this.quots.discount ,
                t_discount:0 ,
                vtype:this.quots.vtype,
                vattype: [
                    {
                        text: 'السعر شامل الضريبة',
                        value: 3,
                    },
                    {
                        text: 'السعر غير شامل الضريبة',
                        value: 2,
                    },
                ],
                invtype:1,
                invtypes: [
                    {
                        text: 'فاتورة آجلة',
                        value: 2,
                    },
                    {
                        text: 'فاتورة نقدية',
                        value: 1,
                    },
                ],
                paytype: 1,
                paytypes: [
                    {text: 'الدفع نقدا', value: 1},
                    {text: 'الشبكة', value: 2},
                    {text: 'دفع متعدد', value: 3},
                ],
                userid: this.quots.agentid,
                users: [

                ]
            },
            fieldRules: [
                v => !!v || 'الحقل مطلوب',
            ],
            tbrows:this.quots.rows,
            newrow:{
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: ''
            },
        }
    },
    created() {
        this.getInvSettings();
        this.changeCtype();
        
        this.calcAll();
        // const samInt = setInterval(() => {
            
        //     if(this.custom != null){
        //         this.getInfo();
        //         clearInterval(samInt)
        //     }
        //     if(this.prods != null){
        //         let pr = [];
        //         for(let i=0;i<this.prods.length;i++){
        //             let ll = {};
        //             ll.item_name = this.prods[i].title;
        //             ll.qty = this.prods[i].qty;
        //             ll.item_price = this.prods[i].price;
        //             ll.total = this.prods[i].total;
        //             ll.vat = this.prods[i].vat;
        //             ll.ftotal = this.prods[i].ftotal;
        //             pr.push(ll);
        //         }
        //         this.tbrows = pr;
        //         this.calcAll();
        //     }
        // },1000);
        // setInterval(() => {
        //     if(document.getElementById('add_invoice') == null){
        //         return false;
        //     }
        //     if(document.getElementById('add_invoice').style.display == 'none'){
        //         this.quots.custom = null;
        //         const samInt = setInterval(() => {
        //             if(this.custom != null){
        //                 this.getInfo();
        //                 clearInterval(samInt)
        //             }
        //         },1000);
        //     }
        // },3000)
    },
    methods: {
        getItemname(){
            if(this.newrow.itemcode == ''){
                return false;
            }
            const post = new FormData();
            post.append("type" , "getItemBycode"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[itemcode]',this.newrow.itemcode)
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data.results.data.results;
                if(res.length != 0){
                    this.newrow.item_name = res[0].name;
                    this.newrow.item_price = res[0].price;
                }
                this.calcKit();
                document.getElementById('newitemqty').focus();
            })
            // .then(() => {
            //     // newrow = {
            //     //     itemcode: '',
            //     //     item_name: '',
            //     //     qty: 1,
            //     //     item_price: 0,
            //     //     total: 0,
            //     //     vat: 0,
            //     //     ftotal: 0
            //     // }
            // })
        },
        getInfo(){
            
            if(this.custom == null) {return false;}
            this.customer.mobile = this.custom.mobile;
            this.company.fullname = this.custom.company;
            this.company.vatid = this.custom.vatid;
            this.getName();
        },
        resetInvoice(){
            this.payby = 1;
            this.itemsworkdes = [
                
            ];
            this.car = {
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
            };
            this.modal = false;
            this.full_total =  0;
            this.full_vat =  0;
            this.full_ftotal =  0;
            this.showcamp =  false;
            this.company = {
                fullname: '',
                vatid: '',
                address: '',
            };
            this.customer =  {
                id:0,
                mobile: '',
                full_name: '',
                cctype: 1,
                ctype: [
                    {
                        text: 'عميل فردي',
                        value: 1,
                    },
                    {
                        text: 'عميل شركة',
                        value: 2,
                    }
                ]
            };
            this.invoice = {
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                payment:{
                    cash: 0,
                    span: 0
                },
                billid: 1,
                discount:this.quots.discount ,
                t_discount:0 ,
                vtype:2,
                vattype: [
                    {
                        text: 'السعر شامل الضريبة',
                        value: 3,
                    },
                    {
                        text: 'السعر غير شامل الضريبة',
                        value: 2,
                    },
                ],
                paytype: 1,
                paytypes: [
                    {text: 'الدفع نقدا', value: 1},
                    {text: 'الشبكة', value: 2},
                    {text: 'دفع متعدد', value: 3},
                ],
                userid: this.quots.agentid,
                users: [

                ]
            };
            this.tbrows = [
            ];
            this.newrow = {
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: 0,
                total: 0,
                vat: 0,
                ftotal: 0
            }
        },
        addInvoice(){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(!this.$refs.forinv.validate()){
                return false;
            }
            if(this.customer.mobile == '') {
                this.customer.mobile = 1234567890;
            }
            if(this.customer.full_name == '') {
                this.customer.full_name = 'عميل عادي';
            }
            if(this.tbrows.length == 0){
                this.$snotify.error('لا يمكن اضافة فاتورة فارغة', 'اضافة فاتورة', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                return false;
            }
           if(this.customer.cctype == 2 && this.company.fullname == ''){
               this.$snotify.error('يرجى ادخال بيانات الشركة', 'اضافة فاتورة', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                return false;
           }
            document.getElementById('addBTN').disabled = true;
            const post = new FormData();
            post.append("type" , "addInvoice"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            for(const [key, value] of Object.entries(this.customer)){
                post.append('data[customer]['+key+']]',value);
            }
            for(const [key, value] of Object.entries(this.company)){
                post.append('data[company]['+key+']]',value);
            }
            for(let i = 0; i < this.tbrows.length;i++){
                for(const [key, value] of Object.entries(this.tbrows[i])){
                    post.append('data[tbrows]['+i+']['+key+']]',value);
                }
            }
            for(const [key, value] of Object.entries(this.car)){
                post.append('data[car]['+key+']]',value);
            }
            post.append("data[inovice][date]",this.invoice.date);
            post.append("data[inovice][itemcode]",0);
            post.append("data[inovice][payment][cash]",this.invoice.payment.cash);
            post.append("data[inovice][payment][span]",this.invoice.payment.span);
            post.append("data[inovice][billid]",this.invoice.billid);
            post.append("data[inovice][vtype]",this.invoice.vtype);
            post.append("data[inovice][invtype]",this.invoice.invtype);
            post.append("data[inovice][paytype]",this.invoice.paytype);
            post.append("data[inovice][userid]",this.invoice.userid);
            post.append("data[inovice][full_total]",this.full_total);
            post.append("data[inovice][full_vat]",this.full_vat);
            post.append("data[inovice][full_ftotal]",this.full_ftotal);
            post.append("data[inovice][t_discount]",this.invoice.t_discount);
            post.append("data[inovice][invtype]",this.invoice.invtype);
            post.append("data[inovice][cash]",this.invoice.payment.cash);
            post.append("data[inovice][span]",this.invoice.payment.span);
            post.append("data[cardid]",this.cardid);
            post.append("data[quotid]",this.$route.params.id);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((resposne) => {
                // console.log(resposne.data);
                if (this.$snotify.notifications.length > 0) {
                    this.$snotify.notifications.forEach(notification => {
                        this.$snotify.remove(notification.id)
                    });
                }
                this.$snotify.success('تمت اضافة الفاتورة بنجاح', 'اضافة فاتورة', {
                    timeout: 2000,
                    showProgressBar: true, 
                    closeOnClick: false,
                    pauseOnHover: true,
                    leftTop:"leftTop"
                });

                this.printPDF(resposne.data.results.data.results.newID);
                
            }).then(() => {
                this.$router.push({name:'InvoiceList'})
                document.getElementById('HideMe').click();
            })

        },
        printInvo(id){
            window.open('../api/print.php?invid='+id,'_blank');
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?invid='+id;
        },
        changePtype(){
            let tbs = [];
             if(this.tbrows.length != 0){
                for(let i = 0; i < this.tbrows.length; i++){
                    
                    const vs = this.$calcVat(this.tbrows[i].ftotal,this.invoice.vtype);
                    const itm = {
                        item_name: this.tbrows[i].item_name,
                        qty: this.tbrows[i].qty,
                        item_price: this.tbrows[i].item_price,
                        total: vs.tot,
                        vat: vs.vat,
                        ftotal: vs.ftot
                    }
                    tbs.push(itm);
                }
            }
            this.tbrows = tbs;
            this.calcAll();
        },
        calcAll(){
            this.full_total = 0;
            this.full_vat = 0;
            this.full_ftotal = 0;
            // console.log(this.tbrows.length);
            if(this.tbrows.length != 0){
                for(let i = 0; i < this.tbrows.length; i++){
                    this.full_total = parseFloat(this.full_total) + parseFloat(this.tbrows[i].total);
                    this.full_vat = parseFloat(this.full_vat) + parseFloat(this.tbrows[i].vat);
                    this.full_ftotal = parseFloat(this.full_ftotal) + parseFloat(this.tbrows[i].ftotal);
                }
            }
            if(this.invoice.discount > 30){
                this.invoice.discount = 30;
            }
            if(this.invoice.discount != 0){
                this.invoice.t_discount = parseFloat(this.invoice.discount) * parseFloat(this.full_total) / parseFloat(100);
                this.full_total = parseFloat(this.full_total) - parseFloat(this.invoice.discount) * parseFloat(this.full_total) / parseFloat(100);
                this.full_vat = parseFloat(this.full_vat) - parseFloat(this.invoice.discount) * parseFloat(this.full_vat) / parseFloat(100);
                this.full_ftotal = parseFloat(this.full_ftotal) - parseFloat(this.invoice.discount) * parseFloat(this.full_ftotal) / parseFloat(100);
            }
            this.full_total = this.$RoundNum(this.full_total);
            this.full_vat = this.$RoundNum(this.full_vat);
            this.full_ftotal = this.$RoundNum(this.full_ftotal);
            if(this.invoice.paytype == 1 || this.invoice.paytype == 3){
                this.invoice.payment.cash = this.full_ftotal;
                this.invoice.payment.span = 0;
            }else if(this.invoice.paytype == 2){
                this.invoice.payment.cash = 0;
                this.invoice.payment.span = this.full_ftotal;
            }
        },
        removeItem(item){
            this.tbrows.splice(item, 1);
            this.calcAll()
        },
        calcKit() {
            const vt = this.invoice.vtype;
            const price = +this.newrow.item_price * +this.newrow.qty;
            const vat = this.$calcVat(price,vt);
            this.newrow.total = this.$RoundNum(vat.tot);
            this.newrow.ftotal = this.$RoundNum(vat.ftot);
            this.newrow.vat = this.$RoundNum(vat.vat);
        },
        addNewRow() {
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(this.newrow.item_name == ''){
                this.$snotify.error('لا يمكن اضافة صنف بدون وصف او البيان', 'اضافة فاتورة', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                return false;
            }
            if(this.newrow.qty == '' || this.newrow.qty == 0){
                this.$snotify.error('يجب أن تكون الكمية عدد اكبر من الصفر', 'اضافة فاتورة', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                return false;
            }
            if(this.newrow.item_price == '' || this.newrow.item_price == 0){
                this.$snotify.error('يجب ان يكون السعر رقم اكبر من الصفر', 'اضافة فاتورة', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                return false;
            }
            this.tbrows.push(this.newrow);
            this.newrow = {
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: 0,
                total: 0,
                vat: 0,
                ftotal: 0
            }
            this.calcAll()
        },
        changeCtype() {
            if(this.customer.cctype == 2){
                this.showcamp = true;
            }else{
                this.showcamp = false;
            }
        },
        getInvSettings() {
            const post = new FormData();
            post.append('type','getInvSettings');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]','all');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res != '' && res.error.number == 200){
                        if(res.results.data.users.length != 0){
                            for(let i= 0; i < res.results.data.users.length; i++){
                                this.invoice.users.push({
                                    text: res.results.data.users[i].full_name,
                                    value: res.results.data.users[i].id
                                })
                            }
                        }
                        if(res.results.data.desk.length != 0){
                            this.itemsworkdes = [];
                            this.allProducts = res.results.data;
                            for(let i= 0; i < res.results.data.desk.length; i++){
                                this.itemsworkdes.push(res.results.data.desk[i].title);
                            }
                        }
                        if(res.results.data.products.length != 0){
                            this.allProducts = res.results.data;
                            for(let i= 0; i < res.results.data.products.length; i++){
                                this.itemsworkdes.push('قطع غيار - ' + res.results.data.products[i].itemcode + ' - ' + res.results.data.products[i].name);
                            }
                        }
                    }
                }
            )
        },
        getName(){
            const post = new FormData();
            post.append('type','customerInfo');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.customer.mobile);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res.error.number == 200){
                        this.customer.id = res.results.data['id'];
                        this.customer.full_name = res.results.data['full_name'];
                    }
                }
            )
        }
    },
}
</script>